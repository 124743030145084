import FormPage from "../../components/FormComponent/Form"
import React from "react"
export default ({location})=>{
  return <FormPage fetchURL={"https://wacoronabe-prd.azurewebsites.net/api/coronasessions"} type={"session"} location={location}/>
}
/*
export const backgrounds = graphql`
  query coronasessionForm {
    allSessions(sort: { order: ASC, fields: index }) {
      edges {
        node {
          id
          img
          img_mobile
          index
          secretCode
          title
          subtitle
          sessionDate
        }
      }
    }
  }
`
*/

